const config = {
    baseURL: 'http://127.0.0.1:8000',
    NovelMainImageStorage: 'https://mynostbucket.s3.ap-northeast-2.amazonaws.com/books',
};

export default config;


//main
//https://nost-stella.com
//dev
//http://127.0.0.1:8000