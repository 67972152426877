import React, { useState, useEffect } from 'react';
import useBookStore from '../../../shared/store/BookStore';
import useThemeStore from '../../../shared/store/Themestore';
import useGlobalStore from '../../../shared/store/GlobalStore';
import './SynopsysResult.scss';
import { deleteSynopsys, generatePrologue } from '../../../features/novel/GenerateNovelInstance';

const SynopsysResult = ({ onComplete }) => {
    const { title, genre, theme, tone, setting, characters, bookId, language, setPrologue, setTranslatedPrologue, setChapterNum, setBookId } = useBookStore();
    const { font, themes, currentSeason } = useThemeStore();
    const { setIsLoading } = useGlobalStore()
    const Seasontheme = themes[currentSeason];

    const [currentPage, setCurrentPage] = useState(1);
    const [editableTitle, setEditableTitle] = useState(title);
    const [editableGenre, setEditableGenre] = useState(genre);
    const [editableTheme, setEditableTheme] = useState(theme);
    const [editableTone, setEditableTone] = useState(tone);
    const [editableSetting, setEditableSetting] = useState(setting);
    const [editableCharacters, setEditableCharacters] = useState(characters);
    console.log("Seasontheme:", Seasontheme)


    useEffect(() => {
        setEditableTitle(title);
        setEditableGenre(genre);
        setEditableTheme(theme);
        setEditableTone(tone);
        setEditableSetting(setting);
        setEditableCharacters(characters);
    }, [title, genre, theme, tone, setting, characters]);





    const handleNextPage = () => setCurrentPage(currentPage + 1);
    const handlePrevPage = () => setCurrentPage(currentPage - 1);

    const handleComplete = async () => {
        setIsLoading(true);
        const prompt = `Recommend the best prologue for me.Title is '${editableTitle}' Genre is '${editableGenre}' Theme is '${editableTheme}' Tone is '${editableTone}' Setting is '${editableSetting}' Characters is '${editableCharacters}'`;

        try {
            console.log("prompt", prompt);
            console.log("language", language);
            const deleteResponse = await deleteSynopsys(bookId);
            if (!deleteResponse.success) throw new Error('Failed to delete prologue');

            const response = await generatePrologue(bookId, prompt, language.value);
            if (!response.success) throw new Error('Failed to generate prologue');

            const { data } = response;
            setBookId(data.book_id);
            setChapterNum(data.chapter_num);
            setPrologue(data.prologue);
            setTranslatedPrologue(data.translated_content);
            onComplete();
        } catch (error) {
            console.error("Error submitting data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            {currentPage === 1 && (
                <div className="SynopsysResult-letter animate-fade-in" style={{ width: "50vw" }}>
                    <div className="editable-field">
                        <strong className="SynopsysResult-strong" style={{ fontFamily: font.shapeFont, color: Seasontheme.blackColor }}>Title</strong>
                        <textarea className="SynopsysResult-textarea" style={{ fontFamily: font.nomalFont, height: "3vh" }} value={editableTitle || ''} onChange={(e) => setEditableTitle(e.target.value)} />
                    </div>
                    <div className="editable-field">
                        <strong className="SynopsysResult-strong" style={{ fontFamily: font.shapeFont, color: Seasontheme.blackColor }}>Genre</strong>
                        <textarea className="SynopsysResult-textarea" style={{ fontFamily: font.nomalFont, height: "3vh" }} value={editableGenre || ''} onChange={(e) => setEditableGenre(e.target.value)} />
                    </div>
                    <div className="editable-field">
                        <strong className="SynopsysResult-strong" style={{ fontFamily: font.shapeFont, color: Seasontheme.blackColor }}>Theme</strong>
                        <textarea className="SynopsysResult-textarea" style={{ fontFamily: font.nomalFont, height: "3vh" }} value={editableTheme || ''} onChange={(e) => setEditableTheme(e.target.value)} />
                    </div>
                    <div className="editable-field">
                        <strong className="SynopsysResult-strong" style={{ fontFamily: font.shapeFont, color: Seasontheme.blackColor }}>Tone</strong>
                        <textarea className="SynopsysResult-textarea" style={{ fontFamily: font.nomalFont, height: "3vh" }} value={editableTone || ''} onChange={(e) => setEditableTone(e.target.value)} />
                    </div>
                    <div className="button-group first-page">
                        <button
                            className="never-button"
                            aria-hidden="true"
                            style={{ visibility: 'hidden' }}
                        >
                            Invisible
                        </button>
                        <button
                            style={{ fontFamily: font.shapeFont, color: Seasontheme.blackColor }}
                            className="next-button"
                            onClick={handleNextPage}
                            onMouseEnter={(e) => {
                                const tooltip = document.createElement('div');
                                tooltip.className = 'tooltip';
                                tooltip.innerText = '수정할 내용이 없다면 next 버튼을 눌러 다음을 진행하세요.';
                                e.target.appendChild(tooltip);
                            }}
                            onMouseLeave={(e) => {
                                const tooltip = e.target.querySelector('.tooltip');
                                if (tooltip) {
                                    tooltip.remove();
                                }
                            }}
                        >
                            Next
                        </button>
                    </div>
                </div>
            )}
            {currentPage === 2 && (
                <div className="SynopsysResult-letter animate-fade-in" style={{ width: "50vw" }}>
                    <div className="editable-field">
                        <strong className='SynopsysResult-strong' style={{ fontFamily: font.shapeFont, color: Seasontheme.blackColor }}>Setting</strong>
                        <textarea
                            className='SynopsysResult-textarea'
                            style={{ fontFamily: font.nomalFont, height: "40vh" }}
                            value={editableSetting || ''}
                            onChange={(e) => setEditableSetting(e.target.value)}
                        />
                    </div>
                    <div className="button-group">
                        <button
                            style={{ fontFamily: font.shapeFont, color: Seasontheme.blackColor }}
                            className="prev-button"
                            onClick={handlePrevPage}
                            onMouseEnter={(e) => {
                                const tooltip = document.createElement('div');
                                tooltip.className = 'tooltip';
                                tooltip.innerText = '이전 내용을 수정하려면 Previous을 클릭하세요.';
                                e.target.appendChild(tooltip);
                            }}
                            onMouseLeave={(e) => {
                                const tooltip = e.target.querySelector('.tooltip');
                                if (tooltip) {
                                    tooltip.remove();
                                }
                            }}
                        >
                            Previous
                        </button>
                        <button
                            style={{ fontFamily: font.shapeFont, color: Seasontheme.blackColor }}
                            className="next-button"
                            onClick={handleNextPage}
                            onMouseEnter={(e) => {
                                const tooltip = document.createElement('div');
                                tooltip.className = 'tooltip';
                                tooltip.innerText = '수정할 내용이 없다면 next 버튼을 눌러 다음을 진행하세요.';
                                e.target.appendChild(tooltip);
                            }}
                            onMouseLeave={(e) => {
                                const tooltip = e.target.querySelector('.tooltip');
                                if (tooltip) {
                                    tooltip.remove();
                                }
                            }}
                        >
                            Next
                        </button>
                    </div>
                </div>
            )}
            {currentPage === 3 && (
                <div className="SynopsysResult-letter animate-fade-in">
                    <div className="editable-field">
                        <strong className='SynopsysResult-strong' style={{ fontFamily: font.shapeFont, color: Seasontheme.blackColor }}>Characters</strong>
                        <textarea
                            className='SynopsysResult-textarea'
                            style={{ fontFamily: font.nomalFont, height: "80vh" }}
                            value={editableCharacters.replace(/\.\.\./g, '\n\n') || ''}
                            onChange={(e) => setEditableCharacters(e.target.value)}
                            rows="10"
                        />
                    </div>
                    <div className="button-group">
                        <button
                            style={{ fontFamily: font.shapeFont, color: Seasontheme.blackColor }}
                            className="prev-button"
                            onClick={handlePrevPage}
                            onMouseEnter={(e) => {
                                const tooltip = document.createElement('div');
                                tooltip.className = 'tooltip';
                                tooltip.innerText = '이전 내용을 수정하려면 Previous을 클릭하세요.';
                                e.target.appendChild(tooltip);
                            }}
                            onMouseLeave={(e) => {
                                const tooltip = e.target.querySelector('.tooltip');
                                if (tooltip) {
                                    tooltip.remove();
                                }
                            }}
                        >
                            Previous
                        </button>
                        <button
                            style={{ fontFamily: font.shapeFont, color: Seasontheme.blackColor }}
                            className="complete-button"
                            onClick={handleComplete}
                            onMouseEnter={(e) => {
                                const tooltip = document.createElement('div');
                                tooltip.className = 'tooltip';
                                tooltip.innerText = '작성한 내용에 따라 소설이 생성됩니다.';
                                e.target.appendChild(tooltip);
                            }}
                            onMouseLeave={(e) => {
                                const tooltip = e.target.querySelector('.tooltip');
                                if (tooltip) {
                                    tooltip.remove();
                                }
                            }}
                        >
                            Accept
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SynopsysResult;
